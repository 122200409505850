<template>
    <div class="wrapper">
        <div class="content">
            <div class="screenBox" v-if="isScreen">
                <!-- 输入客户名称/联系人/电话/手机/邮箱/网址/域名 -->
                <search-bar v-model="keywords" @keyup.enter.native="toSearch" @btnClick="toSearch" :placeholder="$t('mx_mobile.Client.1627021420846')"></search-bar>
            </div>
            <div style="height:1px"></div>
            <div class="moduleCodePage">
                <nodata v-if="firstInto" img="noSearch" title=""></nodata>
                <!-- <scroll v-else class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp"> -->
                <div v-else>
                    <custListView v-if="!loading" :listData="listData" :countNumNo="true" :listLabels="listLabels" :personnelTable="personnelTable" :sysBoxValue="sysBoxValue" @showScreen="showScreen" @RefreshGetData="RefreshGetData"></custListView>
                    <loading-new :loading="loading"></loading-new>
                </div>
                <!-- </scroll> -->
            </div>
        </div>
    </div>
</template>

<script>
import custListView from '../../CustPublic/custListView/index.vue'
import SearchBar from '@/page/Document/Vue/SearchBar/index'
import Scroll from '@/components/Scroll/index'
import loadingNew from '../../CustPublic/LoadingVue/index.vue'
export default {
    name: 'Client',
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Client.1585271749707'), // 加载更多
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Client.1585271760385'), // 没有更多数据了
            startY: 0,
            scrollToX: 0,
            scrollToY: -200,
            scrollToTime: 700,
            scrollToEasing: 'bounce',
            scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],

            msg: 'Client',
            scroll: '',
            listData: [],
            personnelTable: [],
            listLabels: [],
            sort: 'createDate',
            keywords: '',
            firstInto: true,
            pageUrl: '',
            loading: false,
            isScreen: true
        }
    },
    created() {
        this.pageUrl = this.$route.path
        this.unit()
    },
    mounted() {
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    methods: {
        // 按钮进行操作之后，进行回显操作
        RefreshGetData() {
            this.toSearch()
        },
        showScreen(ele) {
            this.isScreen = ele
        },
        async toSearch() {
            if (this.keywords == '') {
                this.listData = []
                return false
            }
            let obj = {
                keywords: this.keywords
            }
            this.listData = await this.getListData(obj)
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        async getListData(obj) {
            let data = {
                size: 6,
                from: 0,
                type: 1
            }
            if (obj) {
                data = Object.assign(data, obj)
            }
            this.loading = true
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.customerCheck_get, { params: data })
            this.loading = false
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                if (this.firstInto) {
                    this.firstInto = false
                }
                return res.data.data.list
            } else {
                this.$toast.fail(res.data.msg)
            }
        },
        unit() {
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                let data = {
                    dataType: 'contact',
                    funType: 'all'
                }
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.accountDropList_get, { params: data }).then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            var p2 = new Promise((resolve, reject) => {
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.label_get, { params: { searchType: 'list', moduleCode: 'BF001' } }).then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            Promise.all([p1, p2]).then(function (results) {
                _this.listLabels = results[1]
                _this.personnelTable = results[0]
            })
        },
        async onPullingDown() {
            this.toSearch()
        },
        async onPullingUp() {
            this.$refs.scroll.forceUpdate(false)
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        }
    },
    watch: {
        $route(to, from) {
            if (to.path) {
                if (to.path == '/client/home') {
                    this.isScreen = true
                    // this.getListData()
                }
            }
        },
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            this.rebuildScroll()
        }
    },
    components: {
        'custListView': custListView,
        'search-bar': SearchBar,
        'Scroll': Scroll,
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
